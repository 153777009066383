
.register-body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: #0a192f;
    color: #333;
}

.container {
    margin: 100px auto;
    max-width: fit-content;
  
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}


.container h2 {
    margin-bottom: 20px;
    color: #007acc;
}

.container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.container button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007acc;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.container button:hover {
    background-color: #005f99;
}


/* .container p {
    margin-top: 10px;
    font-size: 14px;
    color: #007acc;
} */


.container a {
    display: block;
    margin-top: 15px;
    color: #007acc;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.container a:hover {
    color: #005f99;
}