/* Portfolio Container */
.portfolio-container {
    padding: 20px;
    text-align: center;
    background-color: #0a192f;
    min-height: 100vh;
  }
  
  /* Title */
  .portfolio-title {
    font-size: 2em;
    margin: 20px 0;
    color: rgb(100, 255, 218);
  }
  
  /* Project List */
  .project-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default - 3 columns */
    gap: 20px;
    padding: 20px;
  }
  
  @media (max-width: 900px) {
    .project-list {
      grid-template-columns: repeat(2, 1fr); /* Tablets - 2 columns */
    }
  }
  
  @media (max-width: 768px) {
    .project-list {
      grid-template-columns: repeat(1, 1fr); /* Mobile - 1 column */
    }
  }
  
  /* Project Card */
  .project-card {
    display: block;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .project-card:hover {
    /* transform: scale(1.05); */
    box-shadow: 0px 0px 5px 15px aliceblue;
  }
  
  /* Project Image */
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  /* Project Name Container */
  .project-name-container {
    padding: 10px;
    background-color: #333;
  }
  
  /* Project Name */
  .project-name {
    color: #fff;
    font-size: 1.2em;
    margin: 0;
  }
  