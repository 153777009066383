.homecontainer {
    display: flex;
    background-color: #0a192f;
    color: #ccd6f6;
    padding: 50px;
    font-family: Arial, sans-serif;

  }

  /* @media (max-width: 900px) {
    .homecontainer {
      flex-direction: column;
    }
  } */
  
  @media (max-width: 768px) {
    .homecontainer {
      flex-direction: column;
    }
  }
  
  .left-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
  }
  
  .name {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
  }
  
  .title {
    font-size: 1.5rem;
    color: #64ffda;
    margin: 10px 0;
  }
  
  .subtitle {
    font-size: 1.1rem;
    color: #8892b0;
    margin-bottom: 20px;
  }
  
  .nav-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.1rem;
    color: #ccd6f6;
  }
  
  .link {
    text-decoration: none;
    color: #ccd6f6;
    cursor: pointer;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }
  
  .icon {
    color: #8892b0;
    font-size: 1.5rem;
    text-decoration: none;
  }
  
  .right-column {
    flex: 2;
  }
  
  .description {
    font-size: 1rem;
    line-height: 1.6;
    color: #8892b0;
  }
  
  .job-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }
  
  .date {
    font-size: 0.9rem;
    color: #8892b0;
    margin-top: 40px;
  }
  
  .job-title {
    font-size: 1.2rem;
    color: #ccd6f6;
    margin: 10px 0;
  }
  
  .job-description {
    font-size: 1rem;
    color: #8892b0;
    line-height: 1.6;
  }
  
  .header-title {
    font-size: 2rem;
  }
  